<template>
  <div class="container">
<!--    <PrimePreloader v-if="loading"/>-->
    <div class="row mt-4">
      <div class="col-md-2">
        <div class="p-inputgroup">
          <InputText placeholder="Поиск" v-model="search_username"/>
          <Button icon="pi pi-search" @click="search()"/>
        </div>
      </div>

    </div>


    <div v-if="users.length > 0" class="my-4">
      <h4 class="text-center my-3">Пользователи</h4>



          <DataTable :value="users" showGridlines stripedRows
                     :globalFilterFields="['username', 'lastname', 'firstname']"
                     v-model:filters="filters"
                     :paginator="true"
                     :rows="10"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[10,20,50]"
                     responsiveLayout="scroll"
                     currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">

            <template #header>
              <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск"/>
              </span>
              </div>
            </template>
            <Column field="username" header="Логин"></Column>
            <Column field="lastname" header="Фамилия"></Column>
            <Column field="firstname" header="Имя"></Column>
            <Column field="email" header="Email"></Column>
            <Column header="Роли">
              <template #body="{data}">
                <ul>
                  <li v-for="(item, index) in data.roles"
                      :key="index">
                    {{item.item_name}}
                  </li>
                </ul>
              </template>
            </Column>
            <Column header="Действие">
              <template #body="{data}">
                <Button icon="pi pi-sign-in" label="Войти" @click="sign(data.id)"/>
              </template>
            </Column>

          </DataTable>



    </div>
  </div>
</template>


<script>
import {FilterMatchMode, FilterOperator} from "primevue/api";
import {mapActions, mapState} from 'vuex'
import {setCookie} from "@/utils/helpers/cookies.helpers";
import {encryptText} from "@/utils/helpers/crypto.helpers";
export default {
  name: "Users",
  data() {
    return {
      loading: true,
      search_username: null,
      users: [],

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
    }
  },
  computed: {
    //...mapState('organization', ['organizations', 'statuses', 'members', 'membersJoin']),
  },
  methods: {
    ...mapActions(['GET_USERS']),
    async search() {
        if (this.search_username.length >= 4) {
            this.users = await this.GET_USERS(this.search_username);
        }

    },
    async sign(user_id){

      let user = this.users.find(i=>i.id == user_id)
      setCookie('ACCESS_TOKEN', user['access_token'], 3000000)
      setCookie('USERNAME', user['username'], 3000000)
      setCookie('LAST_NAME', user['lastname'], 3000000)
      setCookie('FIRST_NAME', user['firstname'], 3000000)
      setCookie('EMAIL', user['email'], 3000000)
      setCookie('DEPARTMENT_ID', user['kafedra_id'], 3000000)
      setCookie('USER_ID', user['id'], 3000000)
      let roles = []
      for(let i=0; i < user['roles'].length; i++) {
        roles.push(
            encryptText(user['roles'][i].item_name)
        )
      }
      setCookie('ROLES', JSON.stringify(roles), 3000000)
      setCookie('ROLE', null, 3000000)
      window.location.reload();
    }
  },
  async mounted() {


    //this.loading = false
  }
}
</script>

<style scoped>

</style>
